import uuidv1 from 'uuid/v1';

// HEAD DATA
export const headData = {
  title: 'Giusy Castiglione', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Giusy Castiglione, Welcome to my website', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hello, my name is',
  name: 'Giusy.',
  subtitle: `I'm a Front-End Developer.`,
  cta: 'Learn More',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne:
    '1 of 6 children! I grew up in England and Sicily but now reside in NYC with my 2 dogs, Teddy and Cruella. I am passionate about Front End Development and helping other women find their path in the tech sector. In my spare time, I am an avid sailor and you can find me racing in the Hudson River. I also enjoy cooking and hosting dinner parties, working out, reading, playing video games and volunteering.',
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: uuidv1(),
    img: 'smartbrain.png',
    title: 'Smart Brain',
    info:
      'This facial recognition app will detect a face when a .jpg image is given. I built this app in React using the Clarifai API for facial recognition and BCrypt for the security layer. I built the Database with Postgresql.',
    info2: '',
    url: 'https://smart-brain-app-giusy.herokuapp.com/',
    repo: 'https://github.com/giusyc/smart-brain-facial-recognition', // if no repo, the button will not show up
  },
  {
    id: uuidv1(),
    img: 'eventvenue.jpg',
    title: 'Event Venue',
    info:
      'This is a venue project to promote upcoming events at your chosen venue. I built this using React and add on components such as React-slick for the Carousel and React-reveal for the animations.',
    info2: '',
    url: 'https://venue-24y6ru524.now.sh/',
    repo: 'https://github.com/giusyc/VenueProject', // if no repo, the button will not show up
  },
  {
    id: uuidv1(),
    img: 'robofriends.png',
    title: 'RoboFriends',
    info: 'My Robofriends project was built with React and Redux.',
    info2: '',
    url: 'https://robo-friends-md54zgyrn.now.sh/',
    repo: 'https://github.com/giusyc/RoboFriends', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: 'Email me',
  email: 'gc@datespotz.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: uuidv1(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/giusy-castiglione-5768a730/',
    },
    {
      id: uuidv1(),
      name: 'github',
      url: 'https://github.com/giusyc',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: true, // set to false to disable the GitHub stars/fork buttons
};
